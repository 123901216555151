var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"page-Biadjo-view"}},[_c('vs-row',{attrs:{"vs-type":"flex"}},_vm._l((_vm.FormData),function(img){return _c('div',{key:img,staticClass:"centerx con-example-images"},[_c('div',{directives:[{name:"viewer",rawName:"v-viewer",value:({
          toolbar: true,
          navbar: true,
          title: false,
          transition: true,
          keyboard: true,
          zoomable: true,
          scalable: true,
        }),expression:"{\n          toolbar: true,\n          navbar: true,\n          title: false,\n          transition: true,\n          keyboard: true,\n          zoomable: true,\n          scalable: true,\n        }"}],staticClass:"con-img m-2"},[_c('img',{staticClass:"square-full shadow-md cursor-pointer block",attrs:{"src":img,"width":"300","height":"500"}})])])}),0),_c('vs-button',{staticClass:"flex float-right mr-20",attrs:{"type":"border","color":"warning","icon-pack":"feather"},on:{"click":_vm.Back}},[_vm._v(_vm._s(_vm.$t("Back")))])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }