import { render, staticRenderFns } from "./AccidentPictures.vue?vue&type=template&id=51879080&"
import script from "./AccidentPictures.vue?vue&type=script&lang=js&"
export * from "./AccidentPictures.vue?vue&type=script&lang=js&"
import style0 from "./AccidentPictures.vue?vue&type=style&index=0&id=51879080&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports